.footer{
    margin-top: 100px;
    padding-top: 25px;
    height: auto;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 50px;
    background: var(--font-color);
    color: rgb(65, 65, 65);
    padding-bottom: 25px;
    padding-left: 25px;
    padding-right: 25px;
}
.footer h4{
    color: rgb(32, 32, 32);
}
.cate{
    display: grid;
    grid-template-columns: 1fr 1fr;
}
.cate > div{
    cursor: pointer;
    margin-top: 5px;
}
.logo{
    font-size: 30px;
    font-weight: bold;
    color: rgb(0, 0, 0);
}

.copyright{
    background: rgb(230, 230, 230);
    text-align: center;
    font-size: 14px;
    font-weight: lighter;
}

@media screen and (max-width:540px){
    .footer{
        grid-template-columns: 1fr 1fr;
        grid-gap: 25px;
        background: var(--font-color);
    }
}