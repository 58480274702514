.product{
    margin-top: 50px;
}
.product h2{
    color: var(--font-color);
    font-weight: normal;
    font-size: 30px;
}
.p-gal{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 25px;
    margin-top: 25px;
    width: 100%;
}
.watch{
    margin-top: 50px;
}

@media screen and (max-width:540px){
    .p-gal{
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-row-gap: 25px;
        margin-top: 25px;
        width: 100%;
        
    }
    .product h2{
        color: var(--font-color);
        font-weight: normal;
        font-size: 22px;
    }
}
